import React from "react"
import img1 from "../../../static/images/kazim-yardimci/kazim-yardimci-1.jpg"
import img2 from "../../../static/images/kazim-yardimci/kazim-yardimci-2.jpg"
import img3 from "../../../static/images/kazim-yardimci/kazim-yardimci-3.jpg"
import img4 from "../../../static/images/kazim-yardimci/kazim-yardimci-4.jpg"
import LayoutEn from '../../components/layout.en';
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../../hooks/use-site-metadata';

const KazimYardimciEn = ({location}) => {
    const { siteUrl } = useSiteMetadata();

    return (
        <LayoutEn>
            <GatsbySeo
                title="Kazım Yardımcı"
                description="Kâzım Yardımcı was born in I936, in Adıyaman. He dedicated almost all his life to Sufism, and he continued his works for years in this field."
                language="en"
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    locale: "en",
                    title: "Kazım Yardımcı",
                    type: "profile",
                    profile: {
                        firstName: 'Kazım',
                        lastName: 'YARDIMCI',
                        gender: 'male',
                    },
                    images: [
                        {
                            url: `${siteUrl}/images/kazim-yardimci/kazim-yardimci-1.jpg`
                        },
                        {
                            url: `${siteUrl}/images/kazim-yardimci/kazim-yardimci-2.jpg`
                        },
                        {
                            url: `${siteUrl}/images/kazim-yardimci/kazim-yardimci-3.jpg`
                        },
                        {
                            url: `${siteUrl}/images/kazim-yardimci/kazim-yardimci-4.jpg`
                        }
                    ]
                }}
            />
            <section id="page-title" className="page-title-center">

                <div className="container">
                    <h1>Kazım Yardımcı</h1>
                    <span>Information about Kazım Yardımcı</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap pt-5">
                    <div className="container clearfix">
                        <div className="fancy-title title-bottom-border">
                            <h4>Kâzım YARDIMCI</h4>
                        </div>

                        <p>The respectable author, Kâzım Yardımcı was born in I936, in Adıyaman. He had his Primary and Middle (Junior High) School Education in Adıyaman and High School Education in Malatya. Although he did not attend Religious High Schools or Colleges, he learnt Arabic and Ottoman Turkish as a result of his own effort. His mother’s ancestors are from the Family of Hashimites. He lives in Adıyaman together with his Family as a retired civil servant. He dedicated almost all his life to Sufism, and he continued his works for years in this field.</p>
                        <p>Our author has written seven (7) books so far and all of them have been translated into English.</p>
                        <p className="float-right">Kâzım YARDIMCI</p>

                        <div className="divider"></div>

                    </div>
                    <div className="container clearfix">

                        <div className="masonry-thumbs grid-container grid-4" data-lightbox="gallery">
                            <a className="grid-item" href={img1} data-lightbox="gallery-item"><img src={img1} alt="Kazım Yardımcı"/></a>
                            <a className="grid-item" href={img2} data-lightbox="gallery-item"><img src={img2} alt="Kazım Yardımcı"/></a>
                            <a className="grid-item" href={img3} data-lightbox="gallery-item"><img src={img3} alt="Kazım Yardımcı"/></a>
                            <a className="grid-item" href={img4} data-lightbox="gallery-item"><img src={img4} alt="Kazım Yardımcı"/></a>
                        </div>

                    </div>
                </div>
            </section>
        </LayoutEn>
    )
}

export default KazimYardimciEn
